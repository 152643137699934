<template>
  <div>
    <div class="error_page">
      <div class="error_code">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </div>
      <div class="error_msg">
        <div>NOT</div>
        <div>FOUND</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.error_page {
  display: flex;
  justify-content: center;
  color: #2c3e50;
  .error_code {
    span {
      font-weight: 600;
      font-size: 200px;
      text-shadow: 10px 5px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .error_msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 40px;
    font-weight: 500;
    margin-left: 20px;
    div {
      text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
